<template>
  <Layout>
    <div class="section-content">
      <div class="form">
        <div class="form-heading text-center mb-6">
          <h5>{{ title }}</h5>
          <p>
            {{ description }}
          </p>
        </div>
        <div class="flex flex-col sm:flex-row justify-center items-center">
          <Button
            color="white"
            background="gray-800"
            background-hover="gray-700"
            size="large"
            :rounded="true"
            :group="true"
            @action="toLogin"
          >
            {{labelButtonQuery}}
          </Button>
          <Button
            color="white"
            background="lime-600"
            background-hover="lime-700"
            size="large"
            :rounded="true"
            :group="true"
            @action="toRegister"
          >
            {{labelButtonCreate}}
          </Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../components/layout/petition/Layout.vue";
import Button from "../../components/elements/Button.vue";

export default {
  name: "Petition",
  data () {
    return {
      labelButtonQuery: "Consultar petição",
      labelButtonCreate: "Criar petição"
    }
  },
  components: {
    Layout,
    Button
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
    title() {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.main &&
        this.getParamHotsite.pages.petition.main.title
      ) {
        return this.getParamHotsite.pages.petition.main.title;
      } else {
        return "Petição";
      }
    },
    description() {
      if (
        this.getParamHotsite.pages.petition &&
        this.getParamHotsite.pages.petition.main &&
        this.getParamHotsite.pages.petition.main.description
      ) {
        return this.getParamHotsite.pages.petition.main.description;
      } else {
        return `Para efetuar uma nova petição clique no botão criar petição,
            para consultar sua petição clique em já possuo petição`;
      }
    }
  },
  methods: {
    toLogin() {
      this.$router.push({ name: "PetitionLogin" });
    },
    toRegister() {
      this.$router.push({ name: "PetitionRegister" });
    },
  },
  async mounted() {
    let page = this.getParamHotsite.pages.petition
    if (page) {
      if (page.labelButtonCreate) {
        this.labelButtonCreate = page.labelButtonCreate
      }
      if (page.labelButtonQuery) {
        this.labelButtonQuery = page.labelButtonQuery
      }
    }
  }
};
</script>

<style scoped>

</style>
